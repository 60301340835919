<script>
import MultipleSelect from "../elements/multiple-select.vue";
import {mapGetters} from "vuex";
import RelativeDateTerm from "../elements/relative-date-term.vue";

export default {
    name: "campaign-form",

    components: {RelativeDateTerm, MultipleSelect},

    emits: ['saved', 'update'],

    props: {
        campaign: {
            type: Object,
            required: true
        },

        withoutSaveBtn: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            loading: 'campaign/loading',
            states: 'campaign/states',
            errors: 'campaign/errors',
            processes: 'process/all',
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            return this.$store.dispatch(`campaign/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
            })
        },

        setModel: function (model = {}) {
            const base = {}

            this.model = Object.assign(
                {},
                base,
                JSON.parse(JSON.stringify(model))
            )

            if (this.model.is_active === undefined) {
                this.model.is_active = true;
            }

            console.log(this.model)
        },

        fetchProcesses: function () {
            this.$store.dispatch('process/all').then(() => {
                if (this.processes.length === 1) {
                    this.model.process_id = this.processes[0]?.id
                }
            })
        },
    },

    watch: {
        campaign: function (value) {
            this.setModel(value)
        },

        model: {
            deep: true,
            immediate: true,
            handler: function (value) {
                this.$emit('update', Object.assign({}, value))
            },
        }
    },

    created() {
        this.setModel(this.campaign)
        this.fetchProcesses()
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('projects::campaigns.columns.name').ucFirst() }}</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('projects::campaigns.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>

                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.process_id === false}"
                        class="form-label"
                        for="process_id"
                    >{{ $t('projects::campaigns.columns.process_id').ucFirst() }}</label>
                    <select
                        id="process_id"
                        v-model="model.process_id"
                        :class="{'border-danger': states.process_id === false}"
                        class="form-select"
                        name="process_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::campaigns.placeholders.process_id').ucFirst() }}
                        </option>
                        <option v-for="(item, key) in processes" :key="key" :value="item.id">{{
                                item.translation_key ? $t(item.translation_key).ucFirst() : item.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.process_id === false}" class="invalid-feedback">
                        {{ errors.process_id }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4" v-if="false">
                    <label
                        :class="{'text-danger': states.from === false}"
                        class="form-label"
                        for="from"
                    >{{ $t('projects::campaigns.columns.from').ucFirst() }}</label
                    >
                    <input
                        id="from"
                        v-model="model.from"
                        :class="{'border-danger': states.from === false}"
                        :placeholder="$t('projects::campaigns.placeholders.from').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.from === false}" class="invalid-feedback">
                        {{ errors.from }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4" v-if="false">
                    <label
                        :class="{'text-danger': states.to === false}"
                        class="form-label"
                        for="to"
                    >{{ $t('projects::campaigns.columns.to').ucFirst() }}</label
                    >
                    <input
                        id="to"
                        v-model="model.to"
                        :class="{'border-danger': states.to === false}"
                        :placeholder="$t('projects::campaigns.placeholders.to').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.to === false}" class="invalid-feedback">
                        {{ errors.to }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4" v-if="false">
                    <label
                        :class="{'text-danger': states.launched_at === false}"
                        class="form-label"
                        for="launched_at"
                    >{{ $t('projects::campaigns.columns.launched_at').ucFirst() }}</label
                    >
                    <input
                        id="launched_at"
                        v-model="model.launched_at"
                        :class="{'border-danger': states.launched_at === false}"
                        :placeholder="$t('projects::campaigns.placeholders.launched_at').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.launched_at === false}" class="invalid-feedback">
                        {{ errors.launched_at }}
                    </div>
                </div>
                <div class="col-lg-3" v-if="false">
                    <label
                        :class="{'text-danger': states.recurring_time === false}"
                        class="form-label"
                        for="recurring_time"
                    >{{ $t('projects::campaigns.columns.recurring_time').ucFirst() }}</label
                    >
                    <relative-date-term
                        id="recurring_time"
                        v-model="model.recurring_time"
                        :state="states.recurring_time === false"
                        :placeholder="$t('projects::campaigns.placeholders.recurring_time').ucFirst()"
                        name="recurring_time"
                    />
                    <div :class="{'d-block': states.recurring_time === false}" class="invalid-feedback">{{ errors.recurring_time }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.is_active === false}"
                        class="form-label"
                        for="is_active"
                    >{{ $t('projects::campaigns.columns.is_active').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input id="is_active"
                               v-model="model.is_active"
                               class="form-check-input float-none mx-3"
                               type="checkbox"
                        >
                    </div>
                    <div :class="{'d-block': states.is_active === false}" class="invalid-feedback">
                        {{ errors.is_active }}
                    </div>
                </div>
            </div>
            <div class="row" v-if="false">
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.clients === false}"
                        class="form-label"
                        for="clients"
                    >{{ $t('projects::campaigns.relationships.clients').ucFirst() }}</label>
                    <multiple-select id="clients" v-model="model.clients" store="partner" morph="Appon\Partners\Models\Partner"></multiple-select>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button v-if="!withoutSaveBtn" class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
