<script>
import AdvancedTable from "../../../components/elements/advanced-table.vue";
import {mapGetters} from "vuex";
import CampaignForm from "../../../components/forms/campaign-form.vue";

export default {
    components: {
        CampaignForm,
        AdvancedTable,
    },

    props: {
        type: {
            type: String,
            default: 'all'
        }
    },

    computed: {
        ...mapGetters({
            dynamicFields: 'partner/fields',
            authenticated: 'user/authenticated',
            admin: 'auth/admin',
            companySizes: 'companySize/all',
            industries: 'industry/all',
            leadSources: 'leadSource/all',
            count: 'partner/filterCount'
        }),

        fields: function () {
            const fields = [
                {
                    key: "code",
                    label: this.$t('partners::partners.columns.code').ucFirst(),
                    thClass: '',
                    sortable: false
                },
                {
                    key: "name",
                    label: this.$t('partners::partners.columns.name').ucFirst(),
                    thClass: '',
                    sortable: false
                },
                {
                    key: "company_size",
                    label: this.$t('partners::partners.columns.company_size_id').ucFirst(),
                    formatter: (value) => value ? (value.translation_key ? this.$t(value.translation_key).ucFirst() : value.name) : value,
                    thClass: '',
                    sortable: false
                },
                {
                    key: "industry",
                    label: this.$t('partners::partners.columns.industry_id').ucFirst(),
                    formatter: (value) => value ? (value.translation_key ? this.$t(value.translation_key).ucFirst() : value.name) : value,
                    thClass: '',
                    sortable: false
                },
                {
                    key: "lead_source",
                    label: this.$t('partners::partners.columns.lead_source_id').ucFirst(),
                    formatter: (value) => value ? (value.translation_key ? this.$t(value.translation_key).ucFirst() : value.name) : value,
                    thClass: '',
                    sortable: false
                },
            ];

            return fields;
        },

        filters: function () {
            const obj = {}

            if (this.companySizeFilter) {
                obj.company_size_id = this.companySizeFilter
            }

            if (this.leadSourceFilter) {
                obj.lead_source_id = this.leadSourceFilter
            }

            if (this.industryFilter) {
                obj.industry_id = this.industryFilter
            }

            return obj;
        },

        targets: function () {
            const rules = []

            if (this.companySizeFilter) {
                rules.push({
                    column: 'company_size_id',
                    comparison: '=',
                    value: this.companySizeFilter
                })
            }

            if (this.industryFilter) {
                rules.push({
                    column: 'industry_id',
                    comparison: '=',
                    value: this.industryFilter
                })
            }

            if (this.leadSourceFilter) {
                rules.push({
                    column: 'lead_source_id',
                    comparison: '=',
                    value: this.leadSourceFilter
                })
            }

            return [
                {
                    model: 'Appon\\Partners\\Models\\Partner',
                    rules: rules
                }
            ];
        },
    },

    data() {
        return {
            companySizeFilter: null,
            leadSourceFilter: null,
            industryFilter: null,
            campaign: {}
        };
    },

    methods: {
        fetchCount: function () {
            this.$store.dispatch('partner/filterCount', this.filters)
        },

        launch: function () {
            this.$store.dispatch('campaign/launch', Object.assign({}, this.campaign, {targets: this.targets}));
        },
    },

    created() {
        this.fetchCount()
        this.$store.dispatch('partner/fields', { owner_type: 'Appon\\Partners\\Models\\Partner', with: ['fieldType'] })
        this.$store.dispatch('companySize/all')
        this.$store.dispatch('leadSource/all')
        this.$store.dispatch('industry/all')
    },

    watch: {
        companySizeFilter: function () {
            this.fetchCount();
            this.$store.dispatch('partner/list', this.filters);
        },
        leadSourceFilter: function () {
            this.fetchCount();
            this.$store.dispatch('partner/list', this.filters);
        },
        industryFilter: function () {
            this.fetchCount();
            this.$store.dispatch('partner/list', this.filters);
        },
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'partners::partners.partner', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <advanced-table
        :caption="$tc('partners::partners.partner', 2).ucFirst()"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :queries="Object.assign({ type: type, with: ['companySize', 'industry', 'leadSource'] }, filters)"
        :to-be-forwarded="{ type: $t('partners::partners.types.' + type) }"
        borderless
        hover
        moduleNamespace="partner"
        outlined
        permission="partners"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="name"
        sort-direction="asc"
        sort-icon-left
        infinite
    >
        <template #table-title>
            <small>{{ $t('base.the_number_of_hits_matching_the_filters').ucFirst() }}:</small> <span class="bg-primary text-white rounded p-1 px-2">{{ count }}</span>
        </template>
        <template #company_size-filter>
            <div class="input-group">
                <div class="input-group-text">
                    <strong class="text-white px-1" style="background-color: #545965; font-size: 8px;border-radius: 4px;">m-xl</strong>
                </div>
                <select ref="companySizeFilter" class="form-select form-select-sm" v-model="companySizeFilter">
                    <option :value="null">{{ $t('base.please_select').ucFirst() }}</option>
                    <option v-for="(item, index) in companySizes" :key="index" :value="item.id">{{ item.translation_key ? $t(item.translation_key).ucFirst() : item.name }}</option>
                </select>
            </div>
        </template>
        <template #industry-filter>
            <div class="input-group">
                <div class="input-group-text">
                    <i class="fas fa-industry"></i>
                </div>
                <select class="form-select form-select-sm" v-model="industryFilter">
                    <option :value="null">{{ $t('base.please_select').ucFirst() }}</option>
                    <option v-for="(item, index) in industries" :key="index" :value="item.id">{{ item.translation_key ? $t(item.translation_key).ucFirst() : item.name }}</option>
                </select>
            </div>
        </template>
        <template #lead_source-filter>
            <div class="input-group">
                <div class="input-group-text">
                    <i class="fas fa-external-link-alt"></i>
                </div>
                <select class="form-select form-select-sm" v-model="leadSourceFilter">
                    <option :value="null">{{ $t('base.please_select').ucFirst() }}</option>
                    <option v-for="(item, index) in leadSources" :key="index" :value="item.id">{{ item.translation_key ? $t(item.translation_key).ucFirst() : item.name }}</option>
                </select>
            </div>
        </template>
        <template #last-row>
            <div class="w-100">
                <campaign-form :campaign="{}" without-save-btn @update="item => campaign = item"></campaign-form>
            </div>
            <div class="text-center">
                <b-button size="sm" variant="primary" @click="launch">{{ $t('base.launch_item', {item: $t('projects::projects.campaign')}).ucFirst() }}</b-button>
            </div>
        </template>
    </advanced-table>
</template>
